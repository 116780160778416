@tailwind base;
@tailwind components;
.clearfix {
  zoom: 1;
}
.clearfix:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
.section-meals {
  padding: 0;
}

.meals-showcase {
  list-style: none;
  width: 100%;
}

.meals-showcase li {
  display: block;
  float: left;
  width: 25%;
}

.meal-photo {
  width: 100%;
  margin: 0;
  overflow: hidden;
  background-color: #000;
}

.meal-photo img {
  opacity: 0.7;
  width: 100%;
  height: auto;
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
  transform: scale(1.15);
  -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s;
}

.meal-photo img:hover {
  opacity: 1;
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
}
@tailwind utilities;
